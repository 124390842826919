import React from 'react'
import { Link } from 'gatsby'
import ErrorTemplate from '../templates/error/error'

const NotFoundPage = () => {
  const title = 'Page Not found'
  return (
    <ErrorTemplate title={title}>
      <p>
        If you entered a web address please check it was correct, <br />
          or <Link to="/">click here</Link> to go to the homepage.
        </p>
    </ErrorTemplate>
  )
}

export default NotFoundPage
