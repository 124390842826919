import React from 'react'
import PropTypes from 'prop-types'
import styles from './error.module.scss'

const ErrorTemplate = ({ title, children }) => {
  return (
    <div className={styles.el}>
      <div className={styles.el__container}>
        <h1 className={styles.el__heading}>{title}</h1>
        <div className={styles.el__message}>{children}</div>
      </div>
    </div>
  )
}

ErrorTemplate.defaultProps = {
  heading: '',
  children: undefined,
}

ErrorTemplate.propTypes = {
  heading: PropTypes.string,
  children: PropTypes.node,
}

export default ErrorTemplate
